import { initialAppState, IAppState } from "../state/app.state";
import { AppActions, EAppActions } from "../actions/app.actions";

export const appReducers = (
    state = initialAppState,
    action: AppActions
): IAppState => {
    switch(action.type){
        case EAppActions.EnableCarousel: {
            return {
                ...state,
                carouselVisible: true
            }
        }
        case EAppActions.DisableCarousel: {
            return {
                ...state,
                carouselVisible: false
            }
        }
        default:
            return state;
    }
}