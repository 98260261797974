import { Component } from '@angular/core';
import { EEndpointTypes, UiEventService } from '@kissmybutton/nsx-ui-lib';
import { Store, select } from '@ngrx/store';
import { IAppState } from './store/state/app.state';
import { isCarouselVisible } from './store/selectors/app.selectors';
import { EnableCarousel, DisableCarousel } from './store/actions/app.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nsx-ui-lib-tester';
  totalPages = 3;
  itemsPerPage = 2;
  topNSize = 4;
  
  CPU = EEndpointTypes.CPU;
  RAM = EEndpointTypes.RAM;
  SWAP = this.RAM;

  msgPayload: any;
  
  showCarousel$ = this._store.pipe(select(isCarouselVisible))
  constructor(private _store: Store<IAppState>, private eventService: UiEventService){
    this.eventService.uiEventByType('click').subscribe(res => {
      this.msgPayload = JSON.stringify(res);
      setTimeout(()=>{
        this.msgPayload = null;
      },2000);
    });
  }
  
  enableCarousel(){
    this._store.dispatch(new EnableCarousel());
  }

  disableCarousel(){
    this._store.dispatch(new DisableCarousel());
  }
}
