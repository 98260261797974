import { Action } from '@ngrx/store';

export enum EAppActions {
    EnableCarousel= '[App] Enable carousel',
    DisableCarousel= '[App] Disable carousel',
}

export class EnableCarousel implements Action {
    public readonly type = EAppActions.EnableCarousel;
}

export class DisableCarousel implements Action {
    public readonly type = EAppActions.DisableCarousel;
}

export type AppActions = EnableCarousel | DisableCarousel;